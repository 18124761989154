(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name components.filter:chunk
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('components')
    .filter('chunk', chunked);

  function chunked() {
    function cacheIt(func) {
      let cache = {};
      return function (arg) {
        return cache[arg] ? cache[arg] : cache[arg] = func(arg);
      };
    }

    function chunk(items, chunk_size) {
      let chunks = [];
      if (angular.isArray(items)) {
        if (isNaN(chunk_size)) {
          chunk_size = 3;
        }
        for (let i = 0; i < items.length; i += chunk_size) {
          chunks.push(items.slice(i, i + chunk_size));
        }
      }
      return chunks;
    }
    return cacheIt(chunk);
  }
}());
